<template>
  <div class="login-body">
    <div class="card login-panel p-fluid">
      <div class="login-panel-content">
        <Toast />
        <div class="grid">
          <div class="col-12 sm:col-6 md:col-6 logo-container">
            <img src="layout/images/logo-white.png" alt="masipas" />
            <div class="text-900 text-3xl font-medium mb-3">Redefinição de senha</div>
            <span class="guest-sign-in">Digite seu endereço de e-mail e nós lhe enviaremos uma nova senha.</span>
          </div>
          <div class="col-12 username-container">
            <label>E-mail</label>
            <div class="login-input">
              <InputText v-model="form.email" type="text" />
            </div>
          </div>

          <div class="col-6">
            <Button label="Enviar" icon="pi pi-send" @click="sendEmail" />
          </div>

          <div class="col-6 flex align-items-center justify-content-center">
            <router-link to="/login" class="login-link" exact>Voltar para o login</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseService from '../services/BaseService';

export default {
  data() {
    return {
      form: {}
    }
  },
  methods: {
    async sendEmail() {
      const service = new BaseService('/password/forgot');
      try {
        await service.save(this.form);
        this.$toast.add({
          severity: "success",
          summary: "Link de redefinição enviado para o e-mail " + this.form.email,
          life: 3000
        });
        this.$router.push('/login');
      } catch (error) {
        const mensagem  = error?.response?.data?.message ?? error?.message;
        this.$toast.add({
          severity: "error",
          summary: "Erro ao enviar redefinição de senha!",
          detail: mensagem,
          life: 3000
        });
      }
    }
  }
}
</script>
